/* eslint-disable react/sort-comp */
/* eslint-disable max-len */
import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { point, nearestPoint, featureCollection, distance } from "@turf/turf";
import apiKey from "../env";
import {
  getGeoMidpoint,
  generateRoute,
  generateLine,
  generateTurfPoint,
} from "../utils/utils";
import {
  DefaultZoomLevel,
  DefaultLocation,
  GATE,
  FENCE,
} from "../utils/constant";
import JobController from "./JobController";
import DrawingToolBar from "../components/DrawingToolBar";
import SearchBox from "../components/SearchBox";
import DataOverlay from "../overlays/DataOverlay";

const jobDetailsGridMaxWidth = 350;
const pointDistanceThresholdFt = 0.0003048; // 1 foot in km

const styles = () => ({
  root: {
    flexGrow: 1,
    padding: "110px 10px 10px 20px",
    height: "100vh",
  },
  mainGrid: {
    height: "100%",
  },
  jobDetailsGrid: {
    overflow: "auto",
    maxWidth: `${jobDetailsGridMaxWidth}px`,
  },
  mapGrid: {
    height: "100vh",
    maxWidth: `calc(100% - ${jobDetailsGridMaxWidth}px)`,
    marignRight: "auto",
  },
});
class MapController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canClearAll: false,
      canUndo: false,
      canRedo: false,
      vertexCounter: 0,
      totalLength: 0,
      isDrawingFence: false,
      isDrawingGate: false,
      fenceCounter: 0,
      gateCounter: 0,
      fenceLineCounter: 0,
      gateLineCounter: 0,
      fenceDistanceArray: [],
      gateDistanceArray: [],
      fenceOwnership: {},
      gateOwnership: {},
      fenceStyleType: {},
      gateStyleType: {},
      fences: {},
      gates: {},
      fenceLines: {},
      gateLines: {},
    };
    this.mapRef = createRef();
    this.map = createRef();
    this.fencePolyline = [];
    this.gatePolyline = [];
    this.turfPoints = [];
    this.googlePoints = [];
    this.markers = [];
    this.infoWindow = [];
    this.searchboxRef = createRef();
    this.searchboxContainerRef = createRef();
    this.autocomplete = createRef();
  }

  componentDidMount() {
    // TODO: make sure script tag is added only once
    if (!document.getElementById("googleMapScript")) {
      const script = document.createElement("script");
      script.id = "gooleMapScript";
      script.src = `https://maps.googleapis.com/maps/api/js?v=3&key=${apiKey}&libraries=geometry,places&callback=initMap`;
      script.crossorigin = "anonymous";
      document.body.appendChild(script);
      script.addEventListener("load", this.scriptLoad);
    } else {
      this.scriptLoad();
    }
  }

  scriptLoad = () => {
    this.map.current = this.createGoogleMap();
    if (this.map.current) {
      this.createSearchBox();
    }
  };

  createSearchBox() {
    this.autocomplete.current = new window.google.maps.places.Autocomplete(
      this.searchboxRef.current
    );
    this.autocomplete.current.bindTo("bounds", this.map.current);
    this.autocomplete.current.setFields([
      "address_components",
      "geometry",
      "icon",
      "name",
    ]);
    this.autocomplete.current.addListener(
      "place_changed",
      this.placeChangedHandler
    );
    this.map.current.controls[
      window.google.maps.ControlPosition.TOP_CENTER
    ].push(this.searchboxContainerRef.current);
  }

  createGoogleMap = () =>
    new window.google.maps.Map(this.mapRef.current, {
      zoom: DefaultZoomLevel,
      center: DefaultLocation,
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
      tilt: 0,
      fullStreenControl: true,
    });

  createFencePolylineLayer = () =>
    new window.google.maps.Polyline({
      strokeColor: "#FF6700",
      strokeOpacity: 1.0,
      strokeWeight: 4,
      editable: true,
      clickable: true,
      draggable: true,
    });

  createGatePolylineLayer = () =>
    new window.google.maps.Polyline({
      strokeColor: "#FFFF00",
      strokeOpacity: 1.0,
      strokeWeight: 4,
      editable: true,
      clickable: true,
      draggable: true,
    });

  placeChangedHandler = () => {
    const place = this.autocomplete.current.getPlace();
    const marker = new window.google.maps.Marker({
      map: this.map.current,
    });
    if (place.geometry.viewport) {
      this.map.current.fitBounds(place.geometry.viewport);
    } else {
      this.map.current.setCenter(place.geometry.location);
      this.map.current.setZoom(21);
    }
    marker.setPosition(place.geometry.location);
    marker.setVisible(true);
  };

  mapMouseMove = (event) => {
    const {
      vertexCounter,
      fenceCounter,
      gateCounter,
      isDrawingFence,
      isDrawingGate,
    } = this.state;
    if (
      isDrawingFence &&
      this.fencePolyline[fenceCounter].current &&
      this.fencePolyline[fenceCounter].current.getPath().getLength() >
        vertexCounter - 1
    ) {
      this.fencePolyline[fenceCounter].current
        .getPath()
        .setAt(vertexCounter, event.latLng);
    } else if (
      isDrawingGate &&
      this.gatePolyline[gateCounter].current &&
      this.gatePolyline[gateCounter].current.getPath().getLength() >
        vertexCounter - 1
    ) {
      this.gatePolyline[gateCounter].current
        .getPath()
        .setAt(vertexCounter, event.latLng);
    }
  };

  polylineClick = (event) => {
    // const { allHomeowners } = this.props;
    const {
      fenceCounter,
      gateCounter,
      isDrawingFence,
      isDrawingGate,
      fences,
      gates,
      fenceLineCounter,
      gateLineCounter,
      fenceLines,
      gateLines,
    } = this.state;

    const svgMarker = {
      path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
      fillColor: "red",
      fillOpacity: 0.8,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      anchor: new window.google.maps.Point(0, 20),
    };

    this.setState((prevState) => ({
      vertexCounter: prevState.vertexCounter + 1,
    }));

    let pointToAdd = event.latLng;
    let path;

    // snap to nearest point?
    if (
      (isDrawingFence && this.turfPoints.length > 0) ||
      (isDrawingGate && this.turfPoints.length > 0)
    ) {
      const currentPointT = point(generateTurfPoint(pointToAdd));
      const nearestPointT = nearestPoint(
        currentPointT,
        featureCollection(this.turfPoints)
      );
      const distToNPoint = distance(currentPointT, nearestPointT, {
        units: "kilometers",
      });
      if (pointDistanceThresholdFt >= distToNPoint) {
        // lookup near_point
        const indexLookup = this.turfPoints.findIndex(
          (x) =>
            x.geometry.coordinates[0] ===
              nearestPointT.geometry.coordinates[0] &&
            x.geometry.coordinates[1] === nearestPointT.geometry.coordinates[1]
        );
        if (isDrawingFence || isDrawingGate) {
          pointToAdd = this.googlePoints[indexLookup];

          let marker = new window.google.maps.Marker({
            position: pointToAdd,
            map: this.map.current,
            icon: svgMarker,
            title: "Snap!"
          });
          
          setTimeout(function() {
            marker.setMap(null);
            marker = null;
          }, 5000); // 5 seconds
        }
      }
    }

    if (isDrawingFence) {
      path = this.fencePolyline[fenceCounter].current.getPath();
      path.push(pointToAdd);
      this.googlePoints.push(pointToAdd);
      this.turfPoints.push(point(generateTurfPoint(pointToAdd)));
      const fencesState = fences;
      fencesState[fenceCounter] = generateRoute(path);
      if (fencesState[fenceCounter].length > 2) {
        const fenceLinesState = fenceLines;
        fenceLinesState[fenceLineCounter] = generateLine(
          fencesState[fenceCounter]
        );
        this.setState(() => ({ fenceLines: fenceLinesState }));
        this.setState((prevState) => ({
          fenceLineCounter: prevState.fenceLineCounter + 1,
        }));
      }
      this.setState(() => ({ fences: fencesState }));
    } else if (isDrawingGate) {
      path = this.gatePolyline[gateCounter].current.getPath();
      path.push(pointToAdd);
      this.googlePoints.push(pointToAdd);
      this.turfPoints.push(point(generateTurfPoint(pointToAdd)));
      const gatesState = gates;
      gatesState[gateCounter] = generateRoute(path);
      if (gatesState[gateCounter].length > 2) {
        const gateLinesState = gateLines;
        gateLinesState[gateLineCounter] = generateLine(gatesState[gateCounter]);
        this.setState(() => ({ gateLines: gateLinesState }));
        this.setState((prevState) => ({
          gateLineCounter: prevState.gateLineCounter + 1,
        }));
      }
      this.setState(() => ({ gates: gatesState }));
    }

    if (path?.length > 0) {
      this.setState(() => ({
        canClearAll: true,
        canUndo: true,
        canRedo: true,
      }));
    }

    if (path?.length > 2) {
      const prevEvent = path.getAt(path.length - 3);
      const currEvent = path.getAt(path.length - 2);
      const length =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          currEvent,
          prevEvent
        ); // COMPUTED IN METERS
      // const distanceMarker = {
      //   data: length,
      //   pointOnMap: midPoint,
      // };
      // const distances = fenceDistanceArray.slice();
      // distances.push(distanceMarker);

      // const homeownerOptions = document.createElement('div');
      // homeownerOptions.innerText = 'Select line ownership';

      // if (allHomeowners.length) {
      //   allHomeowners.forEach((homeowner) => {
      //     const button = document.createElement('button');
      //     button.onclick = this.handleLineOwnerShipOnClick.bind(this);
      //     button.innerText = homeowner;
      //     button.setAttribute('itemId', fenceCounter);
      //     homeownerOptions.appendChild(button);
      //   });
      // } else {
      //   allHomeowners.innerText = 'Add homeowner for this job first';
      // }
      // const infowindow = new window.google.maps.InfoWindow({
      //   content: homeownerOptions,
      // });
      // const marker = new window.google.maps.Marker({
      //   position: midPoint,
      //   map: this.map.current,
      // });
      // marker.addListener('click', () => {
      //   infowindow.open(this.map.current, marker);
      // });
      // this.markers.push(marker);
      this.setState((prevState) => ({
        totalLength: prevState.totalLength + length,
        // fenceDistanceArray: distances,
      }));
    }

    window.google.maps.event.clearListeners(this.map.current, "click");
  };

  handleLineOwnerShipOnClick = (e, lineType, lineId) => {
    const { fenceOwnership, gateOwnership } = this.state;
    e.preventDefault();
    const targetValue = e.target.value;
    if (lineType === FENCE) {
      console.log('targetValue '+targetValue);
      const ownership = { ...fenceOwnership };
      if ((targetValue.length < 3) && (targetValue.length > 0) ) {
        ownership[lineId] = targetValue;
        console.log('ownership '+ownership[lineId]);
        this.setState(() => ({ fenceOwnership: ownership }));
      } else if (targetValue.length === 0) {
        delete ownership[lineId];
        this.setState(() => ({ fenceOwnership: ownership }));
      }
    }
    if (lineType === GATE) {
      const ownership = { ...gateOwnership };
      if ((targetValue.length < 3) && (targetValue.length > 0) ) {
        ownership[lineId] = targetValue;
        this.setState(() => ({ gateOwnership: ownership }));
      } else if (targetValue.length === 0) {
        delete ownership[lineId];
        this.setState(() => ({ gateOwnership: ownership }));
      }
    }
  };

  handleLineStyleTypeOnClick = (event, lineType, lineId) => {
    const { fenceStyleType, gateStyleType } = this.state;
    console.log('event.target.value ');
    console.log(event.target);
    const targetValue = event.target.value;
    if (lineType === FENCE) {
      const types = { ...fenceStyleType };
      types[lineId] = targetValue;
      this.setState(() => ({ fenceStyleType: types }));
    }
    if (lineType === GATE) {
      const types = { ...gateStyleType };
      types[lineId] = targetValue;
      this.setState(() => ({ gateStyleType: types }));
    }
  };

  updateFenceLineRemoval = (lineId, removal) => {
    const { fenceLines } = this.state;
    const currentState = { ...fenceLines };
    currentState[lineId].removal = removal;
    this.setState({ fenceLines });
  };

  updateGateLineRemoval = (lineId, removal) => {
    const { gateLines } = this.state;
    const currentState = { ...gateLines };
    currentState[lineId].removal = removal;
    this.setState({ gateLines });
  };

  polylineStopDrawing = () => {
    const {
      isDrawingFence,
      isDrawingGate,
      fenceCounter,
      gateCounter,
      fenceDistanceArray,
      gateDistanceArray,
    } = this.state;
    let path;
    if (isDrawingFence) {
      path = this.fencePolyline[fenceCounter].current.getPath();
      path.pop();
    } else if (isDrawingGate) {
      path = this.gatePolyline[gateCounter].current.getPath();
      path.pop();
    }

    window.google.maps.event.clearListeners(this.map.current, "mousemove");
    this.setState((prevState) => {
      if (prevState.isDrawingFence) {
        const newDistance = fenceDistanceArray;
        newDistance[newDistance.length - 2] = null;
        return {
          fenceCounter: prevState.fenceCounter + 1,
          isDrawingFence: false,
          vertexCounter: 0,
          fenceDistanceArray: newDistance,
        };
      }
      if (prevState.isDrawingGate) {
        const newDistance = gateDistanceArray;
        newDistance[newDistance.length - 2] = null;
        return {
          gateCounter: prevState.gateCounter + 1,
          isDrawingGate: false,
          vertexCounter: 0,
          gateDistanceArray: newDistance,
        };
      }
      return {};
    });
  };

  polylineEdited = (polyline, polylineType, counter) => {
    const { fences, gates, fenceDistanceArray, gateDistanceArray } = this.state;
    if (polylineType === FENCE) {
      this.fencePolyline[counter].current = polyline;
      const path = polyline.getPath();
      const route = generateRoute(path);
      const copy = fences;
      copy[counter] = route;
      const fenceDistance = fenceDistanceArray.slice();
      let arrayCounter = 0;
      Object.keys(fences).forEach((id) => {
        if (fences[id].length > 1) {
          fences[id].forEach((latLng, index) => {
            if (index < fences[id].length - 1) {
              const p1 = new window.google.maps.LatLng(latLng.lat, latLng.lng);
              const p2 = new window.google.maps.LatLng(
                fences[id][index + 1].lat,
                fences[id][index + 1].lng
              );
              const midPoint = getGeoMidpoint(p1, p2);
              const lineDistance =
                window.google.maps.geometry.spherical.computeDistanceBetween(
                  p1,
                  p2
                );
              const distanceMarker = {
                p1,
                p2,
                lineId: arrayCounter,
                data: lineDistance,
                pointOnMap: midPoint,
              };
              fenceDistance[arrayCounter] = distanceMarker;
              arrayCounter += 1;
            }
          });
        }
      });
      fenceDistance[arrayCounter] = null;
      const newTotal =
        fenceDistance.reduce((acc, val) => acc + (val ? val.data : 0), 0) +
        gateDistanceArray.reduce((acc, val) => acc + (val ? val.data : 0), 0);
      this.setState(() => ({
        fences: copy,
        fenceDistanceArray: fenceDistance,
        totalLength: newTotal,
      }));
    } else if (polylineType === GATE) {
      this.gatePolyline[counter].current = polyline;
      const path = polyline.getPath();
      const route = generateRoute(path);
      const copy = gates;
      copy[counter] = route;
      const gateDistance = gateDistanceArray.slice();
      let arrayCounter = 0;
      Object.keys(gates).forEach((id) => {
        if (gates[id].length > 1) {
          gates[id].forEach((latLng, index) => {
            if (index < gates[id].length - 1) {
              const p1 = new window.google.maps.LatLng(latLng.lat, latLng.lng);
              const p2 = new window.google.maps.LatLng(
                gates[id][index + 1].lat,
                gates[id][index + 1].lng
              );
              const midPoint = getGeoMidpoint(p1, p2);
              const gateLineDistance =
                window.google.maps.geometry.spherical.computeDistanceBetween(
                  p1,
                  p2
                ); // COMPUTED TO METERS
              const distanceMarker = {
                p1,
                p2,
                lineId: arrayCounter,
                data: gateLineDistance,
                pointOnMap: midPoint,
              };
              gateDistance[arrayCounter] = distanceMarker;
              arrayCounter += 1;
            }
          });
        }
      });
      gateDistance[arrayCounter] = null;
      const newTotal =
        fenceDistanceArray.reduce((acc, val) => acc + (val ? val.data : 0), 0) +
        gateDistance.reduce((acc, val) => acc + (val ? val.data : 0), 0);
      this.setState(() => ({
        gates: copy,
        gateDistanceArray: gateDistance,
        totalLength: newTotal,
      }));
    }
  };

  addDrawingHandler = (event, type) => {
    if (event.target.innerText === "Stop") {
      if (type === FENCE) {
        this.setState(() => ({ isDrawingFence: false }));
      } else if (type === GATE) {
        this.setState(() => ({ isDrawingGate: false }));
      }
    } else {
      if (type === FENCE) {
        const { fenceCounter } = this.state;
        this.fencePolyline.push(createRef());
        const newPolylineLayer = this.createFencePolylineLayer();
        this.fencePolyline[fenceCounter].current = newPolylineLayer;
        this.fencePolyline[fenceCounter].current.setMap(this.map.current);
        this.fencePolyline[fenceCounter].current.addListener(
          "click",
          this.polylineClick
        );
        this.fencePolyline[fenceCounter].current.addListener(
          "rightclick",
          this.polylineStopDrawing
        );
        this.fencePolyline[fenceCounter].current.addListener(
          "mousemove",
          this.mapMouseMove
        );
        window.google.maps.event.addListener(newPolylineLayer, "dragend", () =>
          this.polylineEdited(newPolylineLayer, FENCE, fenceCounter)
        );
        window.google.maps.event.addListener(
          newPolylineLayer.getPath(),
          "insert_at",
          () => this.polylineEdited(newPolylineLayer, FENCE, fenceCounter)
        );
        window.google.maps.event.addListener(
          newPolylineLayer.getPath(),
          "remove_at",
          () => this.polylineEdited(newPolylineLayer, FENCE, fenceCounter)
        );
        window.google.maps.event.addListener(
          newPolylineLayer.getPath(),
          "set_at",
          () => this.polylineEdited(newPolylineLayer, FENCE, fenceCounter)
        );

        this.setState(() => ({ isDrawingFence: true }));
      } else if (type === GATE) {
        const { gateCounter } = this.state;
        this.gatePolyline.push(createRef());
        const newGatePolyline = this.createGatePolylineLayer();
        this.gatePolyline[gateCounter].current = newGatePolyline;
        this.gatePolyline[gateCounter].current.setMap(this.map.current);
        this.gatePolyline[gateCounter].current.addListener(
          "click",
          this.polylineClick
        );
        this.gatePolyline[gateCounter].current.addListener(
          "rightclick",
          this.polylineStopDrawing
        );
        this.gatePolyline[gateCounter].current.addListener(
          "mousemove",
          this.mapMouseMove
        );
        window.google.maps.event.addListener(newGatePolyline, "dragend", () =>
          this.polylineEdited(newGatePolyline, GATE, gateCounter)
        );
        window.google.maps.event.addListener(
          newGatePolyline.getPath(),
          "insert_at",
          () => this.polylineEdited(newGatePolyline, GATE, gateCounter)
        );
        window.google.maps.event.addListener(
          newGatePolyline.getPath(),
          "remove_at",
          () => this.polylineEdited(newGatePolyline, GATE, gateCounter)
        );
        window.google.maps.event.addListener(
          newGatePolyline.getPath(),
          "set_at",
          () => this.polylineEdited(newGatePolyline, GATE, gateCounter)
        );
        this.setState(() => ({ isDrawingGate: true }));
      }
      this.map.current.setOptions({ draggableCursor: "crosshair" });
      this.map.current.addListener("click", this.polylineClick);
      this.map.current.addListener("mousemove", this.mapMouseMove);
    }
  };

  clearAll = () => {
    // const { distanceArray } = this.state;
    // distanceArray.forEach((overlay) => {
    //   overlay.state.OVERLAY.setMap(null);
    // });
    this.turfPoints = [];
    this.googlePoints = [];
    this.fencePolyline.forEach((polyline) => {
      // TODO: is there async function that's causing the occasional null object?
      if (polyline && polyline.current) {
        polyline.current.setMap(null);
        polyline.current.getPath().clear();
      }
    });
    this.gatePolyline.forEach((polyline) => {
      if (polyline && polyline.current) {
        polyline.current.setMap(null);
        polyline.current.getPath().clear();
      }
    });
    this.setState(() => ({
      canClearAll: false,
      canRedo: false,
      canUndo: false,
      vertexCounter: 0,
      isDrawingFence: false,
      isDrawingGate: false,
      fenceDistanceArray: [],
      gateDistanceArray: [],
      totalLength: 0,
      fenceCounter: 0,
      gateCounter: 0,
      fenceLineCounter: 0,
      gateLineCounter: 0,
      fences: {},
      gates: {},
      fenceLines: {},
      gateLines: {},
      fenceOwnership: {},
      gateOwnership: {},
      fenceStyleType: {},
      gateStyleType: {},
    }));
    this.markers.forEach((marker) => {
      if (marker) {
        marker.setMap(null);
      }
    });
    this.map.current.setOptions({ draggableCursor: "" });
  };

  render() {
    const {
      classes,
      selectUnit,
      prevUnit,
      currUnit,
      user,
      allHomeowners,
    } = this.props;
    const {
      canClearAll,
      canUndo,
      canRedo,
      totalLength,
      isDrawingFence,
      isDrawingGate,
      fenceDistanceArray,
      gateDistanceArray,
      fences,
      gates,
      fenceLines,
      gateLines,
      fenceOwnership,
      gateOwnership,
      fenceStyleType,
      gateStyleType,
    } = this.state;

    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          xs={12}
          wrap="nowrap"
          direction="row"
          className={classes.mainGrid}
        >
          <Grid item md={3} xs={12} className={classes.jobDetailsGrid}>
            <JobController
              totalLength={totalLength}
              selectUnit={selectUnit}
              prevUnit={prevUnit}
              currUnit={currUnit}
              user={user}
              allHomeowners={allHomeowners}
              fences={fences}
              gates={gates}
              fenceLines={fenceLines}
              gateLines={gateLines}
              fenceOwnership={fenceOwnership}
              gateOwnership={gateOwnership}
              fenceDistanceArray={fenceDistanceArray}
              gateDistanceArray={gateDistanceArray}
              fenceStyleType={fenceStyleType}
              gateStyleType={gateStyleType}
              handleLineOwnerShipOnClick={this.handleLineOwnerShipOnClick}
              handleLineStyleTypeOnClick={this.handleLineStyleTypeOnClick}
              updateFenceLineRemoval={this.updateFenceLineRemoval}
              updateGateLineRemoval={this.updateGateLineRemoval}
            />
          </Grid>
          <Grid item md={9} xs={12} className={classes.mapGrid}>
            {isDrawingGate || isDrawingFence ? (
              <div>you can also right click to stop drawing</div>
            ) : (
              <div />
            )}
            <DrawingToolBar
              canClearAll={canClearAll}
              canUndo={canUndo}
              canRedo={canRedo}
              clearAll={this.clearAll}
              addDrawingHandler={this.addDrawingHandler}
              isDrawingFence={isDrawingFence}
              isDrawingGate={isDrawingGate}
            />
            <SearchBox
              ref={{
                containerRef: this.searchboxContainerRef,
                inputRef: this.searchboxRef,
              }}
            />
            <div
              id="google-map"
              ref={this.mapRef}
              style={{ width: "100%", height: "75vh", margin: "auto" }}
            />
            {this.map.current &&
              fenceDistanceArray.map((item, index) => (
                <DataOverlay
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  item={item}
                  index={index}
                  map={this.map.current}
                  prevUnit={prevUnit}
                  currUnit={currUnit}
                />
              ))}
            {this.map.current &&
              gateDistanceArray.map((item, index) => (
                <DataOverlay
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  item={item}
                  index={index}
                  map={this.map.current}
                  prevUnit={prevUnit}
                  currUnit={currUnit}
                />
              ))}
          </Grid>
        </Grid>
      </div>
    );
  }
}

MapController.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.object,
    mainGrid: PropTypes.object,
    jobDetailsGrid: PropTypes.object,
    mapGrid: PropTypes.object,
  }).isRequired,
  selectUnit: PropTypes.func.isRequired,
  currUnit: PropTypes.string.isRequired,
  prevUnit: PropTypes.string.isRequired,
  user: PropTypes.object,
  allHomeowners: PropTypes.array.isRequired,
};

MapController.defaultProps = {
  user: null,
};

export default withStyles(styles)(MapController);
