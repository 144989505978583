import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Convert from 'convert-units';
import { customRoundup } from '../utils/utils';

class DataOverlay extends React.Component {
  constructor(props) {
    super(props);
    const overlayView = new window.google.maps.OverlayView();
    overlayView.onAdd = this.onAdd.bind(this);
    overlayView.onRemove = this.onRemove.bind(this);
    overlayView.draw = this.draw.bind(this);
    overlayView.setMap(props.map);
    this.state = {
      OVERLAY: overlayView,
    };
    this.span = null;
  }

  shouldComponentUpdate(nextProps) {
    const { currUnit, prevUnit, item } = nextProps;
    if (this.span) {
      if (item) {
        this.span.innerHTML = `#${item.lineId}: ${customRoundup(Convert(item.data).from(prevUnit).to(currUnit))}`;
      } else {
        this.span.innerHTML = '';
      }
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    const { OVERLAY } = this.state;
    _.delay(OVERLAY.draw);
  }

  componentWillUnmount() {
    const { OVERLAY } = this.state;
    OVERLAY.setMap(null);
    this.onAdd = null;
    this.draw = null;
    this.onRemove = null;
  }

  onAdd() {
    const { OVERLAY } = this.state;
    this.span = document.createElement('span');
    this.span.style.position = 'absolute';
    this.span.class = 'overlay';

    const panes = OVERLAY.getPanes();
    panes.floatPane.appendChild(this.span);
  }

  onRemove() {
    if (this.span && this.span.parentNode) {
      this.span.parentNode.innerHTML = ''; // this is a hack. Overlay nodes were added twice. Need to figure out why.
      ReactDOM.unmountComponentAtNode(this.span);
      this.span = null;
    }
  }

  draw() {
    const {
      item, currUnit, prevUnit,
    } = this.props;
    const { OVERLAY } = this.state;

    if (this.span && item) {
      const dataToDisplay = customRoundup(Convert(item.data).from(prevUnit).to(currUnit));
      const projection = OVERLAY.getProjection();
      const position = projection.fromLatLngToDivPixel(
        new window.google.maps.LatLng(item.pointOnMap.lat - 0.000007, item.pointOnMap.lng),
      );
      this.span.style.left = `${position.x}px`;
      this.span.style.top = `${position.y - 30}px`;
      this.span.style.display = 'block';
      // this.span.style.background = 'black';
      this.span.style.color = 'white';
      this.span.style.lineHeight = '30px';
      this.span.style.fontSize = '1.7em';
      this.span.style.fontWeight = 'bold';
      this.span.style.opacity = 1;
      this.span.style.webkitTextStroke = 'black';
      this.span.style.webkitTextStrokeWidth = '1px';
      this.span.style.webkitTextFillColor = 'white';
      this.span.innerHTML = `#${item.lineId}: ${dataToDisplay}`;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return false;
  }
}

DataOverlay.propTypes = {
  item: PropTypes.shape({
    pointOnMap: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }).isRequired,
    data: PropTypes.number,
    lineId: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  map: PropTypes.object.isRequired,
  currUnit: PropTypes.string.isRequired,
  prevUnit: PropTypes.string.isRequired,
};

DataOverlay.defaultProps = {
  item: null,
};

export default DataOverlay;
