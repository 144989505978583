import React from 'react';
import Button from '@material-ui/core/Button';
import { auth } from '../../services/firebaseUtils';

function SignOutComponent(setCanFetchHomeowners) {
  return (
    <Button color="inherit" onClick={() => auth.signOut().then(() => {
      setCanFetchHomeowners(true);
    })}>Logout</Button>
  );
}

export default SignOutComponent;
