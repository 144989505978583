/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { object, array, func } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import firebase from 'firebase/app';
import NewHomeownerForm from '../forms/NewHomeownerForm';
import { db } from '../services/firebaseUtils';
import HomeownerCard from '../components/Homeowner/HomeownerCard';
import AddNewHomeownerCard from '../components/Homeowner/AddNewHomeownerCard';
import '../sass/jobdetailform.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '5vw',
    paddingTop: '10%',
    height: '100%',
    overflow: 'auto',
  },
  headingContainer: {
    height: '50px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    lineHeight: '50px',
    marginLeft: '15px',
  },
  body: {
    width: '70%',
    margin: 'auto',
  },
  button: {
    margin: '3% 2% 0 2%',
    width: '40%',
  },
}));

const initialState = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  address: '',
  city: '',
  postcode: '',
  province: '',
};

export default function HomeownerController(props) {
  const classes = useStyles();
  const { user, allHomeowners, setAllHomeowners, setCanFetchHomeowners, } = props;
  const [{
    firstname,
    lastname,
    email,
    phone,
    address,
    city,
    postcode,
    province,
  }, setState] = useState(initialState);

  const [showForm, setShowForm] = useState(false);

  const onChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({ ...prevState, [id]: value }));
  };

  const clearState = () => {
    setState({ ...initialState });
    setCanFetchHomeowners(true);
  };

  const deleteHomeownerFromState = (homeownerId) => {
    const { [homeownerId]: _, ...remainingHomeowners } = allHomeowners;
    setAllHomeowners(remainingHomeowners);
  };  

  const addHomeownerToState = (homeownerRef) => {
    let newAllHomeowners = { ...allHomeowners, [homeownerRef.id]: homeownerRef.data() };
    setAllHomeowners(newAllHomeowners);
  };  

  useEffect(() => {
    if (showForm) {
      const element = document.getElementById('newHomeownerForm');
      element?.scrollIntoView();
    }
  }, [showForm, setShowForm]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={3} sm={4} xs={12}>
          <AddNewHomeownerCard addNewHomeonerClickHandler={setShowForm} />
        </Grid>
        {
          Object.keys(allHomeowners).map((key) => (
            <Grid key={key} item lg={3} sm={4} xs={12}>
              <HomeownerCard 
                homeowner={allHomeowners[key]} 
                homeownerId={key}
                user={user} 
                deleteHomeownerFromState={deleteHomeownerFromState}
                />
            </Grid>
          ))
        }
      </Grid>
      {
        showForm ? (
          <NewHomeownerForm
            closeForm={setShowForm}
            user={user}
            allHomeowners={allHomeowners}
            addHomeownerToState={addHomeownerToState}
          />
        ) : null
      }

    </div>
  );
}

HomeownerController.propTypes = {
  user: object,
  allHomeowners: array.isRequired,
  setAllHomeowners: func.isRequired,
  setCanFetchHomeowners: func.isRequired,
};

HomeownerController.defaultProps = {
  user: null,
};
