import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { GATE, FENCE } from '../utils/constant';
import '../sass/drawingtoolbar.scss';

class DrawingToolbar extends React.Component {
  handleClearAll = () => {
    const { clearAll } = this.props;
    clearAll();
  }

  addFenceOnClickHandler = (e) => {
    const { addDrawingHandler } = this.props;
    addDrawingHandler(e, FENCE);
  }

  addGateOnClickHandler = (e) => {
    const { addDrawingHandler } = this.props;
    addDrawingHandler(e, GATE);
  }

  render() {
    const {
      canClearAll,
      // canUndo, canRedo,
      isDrawingFence, isDrawingGate,
    } = this.props;
    return (
      <div className="drawing-toolbar">
        <Button
          variant="contained"
          color="secondary"
          onClick={this.addFenceOnClickHandler}
          disabled={isDrawingGate}
        >
          {isDrawingFence ? 'Stop' : 'Add Fence'}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={this.addGateOnClickHandler}
          disabled={isDrawingFence}
        >
          {isDrawingGate ? 'Stop' : 'Add Gate'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!canClearAll}
          onClick={this.handleClearAll}
        >
          CLEAR ALL
        </Button>
        {/* <Button variant="contained" color="primary" disabled={!canUndo}>
          UNDO
        </Button>
        <Button variant="contained" color="primary" disabled={!canRedo}>
          REDO
        </Button> */}
      </div>
    );
  }
}

DrawingToolbar.propTypes = {
  canClearAll: PropTypes.bool.isRequired,
  // canUndo: PropTypes.bool.isRequired,
  // canRedo: PropTypes.bool.isRequired,
  clearAll: PropTypes.func.isRequired,
  addDrawingHandler: PropTypes.func.isRequired,
  isDrawingFence: PropTypes.bool.isRequired,
  isDrawingGate: PropTypes.bool.isRequired,
};

export default DrawingToolbar;
