import React from 'react';
import PropTypes, { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const styles = () => ({
  formControl: {
    margin: 4,
    minWidth: 120,
  },
});

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueSelected: (this.props.menuItems.length > 1) && 
      (this.props.menuItems.findIndex(item => Object.values(item).includes("removal_only")) === -1 ? false : true) ? 
      this.props.menuItems[this.props.menuItems.findIndex(item => Object.values(item).includes("removal_only"))-1].value : '',
      stateLineStyleType: this.props.lineStyleType,
    };
    // this.state = {
    //   valueSelected: '',
    // };
  }

  handleChange = (event, ...props) => {
    const { setItemValue, value } = this.props;
    setItemValue(event, ...props);
    if (value === undefined) {
      // console.log('here');
      this.setState(() => ({
        valueSelected: event.target.value,
      }));
    }
  }

  render() {
    const { valueSelected } = this.state;
    const {
      classes, dropdownLabel, menuItems, variant, IconComponent, value, lineKey, isLine,
    } = this.props;
    if (this.state.stateLineStyleType !== undefined) {
      // console.log("key log  "+lineKey+' '+!Object.keys(this.state.stateLineStyleType).includes(lineKey));
      // console.log("state")
      // console.log(this.state.stateLineStyleType);
      // console.log("prop ")
      // console.log(lineStyleType);
      if ( (!Object.keys(this.state.stateLineStyleType).includes(lineKey)) &&
        (menuItems.length > 1) && 
        (menuItems.findIndex(item => Object.values(item).includes("removal_only")) === -1 ? false : true)) {
          const newValue = menuItems[menuItems.findIndex(item => Object.values(item).includes("removal_only"))-1].value;
          this.setState(prevState => ({
            stateLineStyleType: {
              ...prevState.stateLineStyleType,
              [lineKey]: newValue,
            },
          }));
          this.handleChange({target: {value: newValue}});
      } 
    }
      

    // console.log('Default ', menuItems[this.props.menuItems.findIndex(item => Object.values(item).includes("Removal Only"))-1]);
    for (let i = 0; i < menuItems.length; i++) {
      console.log('menuItems[i] ', menuItems[i]);
    }

    return (
      <FormControl variant={variant} className={classes.formControl}>
        <InputLabel id="select-input-helper-label">{dropdownLabel}</InputLabel>
        <Select
          IconComponent={IconComponent}
          labelId="select-helper-label"
          id="simple-select-helper"
          value={value === undefined ? valueSelected : value}
          onChange={this.handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          {
            menuItems.map((item) => (
              <MenuItem
                key={item.id}
                value={isLine ? item.value : item.id}
              >
                {item.value}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
  }
}

Dropdown.propTypes = {
  dropdownLabel: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  classes: PropTypes.shape({
    formControl: PropTypes.string,
  }).isRequired,
  value: PropTypes.string,
  lineKey: PropTypes.number,
  lineStyleType: object,
  isLine: PropTypes.bool,
  setItemValue: PropTypes.func.isRequired,
  variant: PropTypes.string,
  IconComponent: PropTypes.element,
};

Dropdown.defaultProps = {
  variant: 'standard',
  IconComponent: ArrowDropDownIcon,
  value: undefined,
  isLine: false,
};

export default withStyles(styles)(Dropdown);
