import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import AddIcon from '@material-ui/icons/Add';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    minHeight: 275,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '100px',
  },
});

export default function AddNewHomeownerCard(props) {
  const { addNewHomeonerClickHandler } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.content}>
        <Button
          size="large"
          startIcon={<AddIcon />}
          onClick={() => addNewHomeonerClickHandler(true)}
        >
          Add Homeowner
        </Button>
      </CardContent>
    </Card>
  );
}

AddNewHomeownerCard.propTypes = {
  addNewHomeonerClickHandler: PropTypes.func.isRequired,
};
