/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { object, array, func } from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { saveHomeowner } from '../services/firebaseRequests';
import '../sass/jobdetailform.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    paddingTop: '10%',
  },
  headerButton: {
    marginLeft: '80%',
  },
  body: {
    width: '70%',
    margin: 'auto',
  },
  button: {
    margin: '3% 2% 0 2%',
    width: '40%',
  },
}));

const initialState = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  address: '',
  city: '',
  postcode: '',
  province: '',
};

export default function NewHomeownerForm(props) {
  const classes = useStyles();
  const {
    user, allHomeowners, addHomeownerToState,
    closeForm,
  } = props;
  const [{
    firstname,
    lastname,
    email,
    phone,
    address,
    city,
    postcode,
    province,
  }, setState] = useState(initialState);

  const onChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({ ...prevState, [id]: value }));
  };

  const clearState = () => {
    setState({ ...initialState });
  };

  const handleSaveHomeowner = () => {
    saveHomeowner(user, firstname, lastname, email, phone, address,
      city, postcode, province).then((res) => {
        addHomeownerToState(res)
        clearState();
      
    }).catch((error) => {
      console.log('Error occurred during saving homewoner.');
    });
  };

  return (
    <div id="newHomeownerForm" className={classes.root}>
      <Button
        className={classes.headerButton}
        onClick={() => closeForm(false)}
        endIcon={<CloseIcon />}
      >
        close
      </Button>
      <div className={classes.body}>
        <TextField
          required
          id="firstname"
          label="First Name"
          type="text"
          onChange={onChange}
          value={firstname}
          fullWidth
        />
        <TextField
          required
          id="lastname"
          type="text"
          label="Last Name"
          onChange={onChange}
          value={lastname}
          fullWidth
        />
        <TextField
          required
          id="email"
          type="email"
          label="Email"
          fullWidth
          onChange={onChange}
          value={email}
        />
        <TextField
          required
          id="phone"
          type="tel"
          label="Phone Number"
          fullWidth
          onChange={onChange}
          value={phone}
        />
        <TextField
          required
          id="address"
          type="text"
          label="Home Address"
          fullWidth
          onChange={onChange}
          value={address}
        />
        <TextField
          required
          id="city"
          type="text"
          label="City"
          fullWidth
          onChange={onChange}
          value={city}
        />
        <TextField
          required
          id="province"
          type="text"
          label="Province"
          fullWidth
          onChange={onChange}
          value={province}
        />
        <TextField
          required
          id="postcode"
          type="text"
          label="Post Code"
          fullWidth
          onChange={onChange}
          value={postcode}
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveHomeowner}
            className={classes.button}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={clearState}
            className={classes.button}
          >
            Clear
          </Button>
        </div>
      </div>
    </div>
  );
}

NewHomeownerForm.propTypes = {
  user: object,
  allHomeowners: array.isRequired,
  addHomeownerToState: func.isRequired,
  closeForm: func.isRequired,
};

NewHomeownerForm.defaultProps = {
  user: null,
};
