/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Box, Dialog, DialogTitle, DialogContent, Tabs, Tab,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SignInForm from './SignIn';
import SignUpForm from './SignUp';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px auto 20px auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

function LoginDialog(props) {
  const { onClose, open, setDisplayName } = props;
  const classes = useStyles();
  const [action, setAction] = useState(0);

  const handleTabChange = (_, newValue) => {
    setAction(newValue);
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <Tabs
        value={action}
        onChange={handleTabChange}
        centered
      >
        <Tab label="Sign In" />
        <Tab label="Sign Up" />
      </Tabs>
      {action === 0 ? (
        <Box className={classes.form}>
          <DialogTitle>
            Sign In
          </DialogTitle>
          <DialogContent>
            <SignInForm className={classes.formControl} />
          </DialogContent>
        </Box>
      ) : (
        <Box className={classes.form}>
          <DialogTitle>
            Sign Up
          </DialogTitle>
          <DialogContent>
            <SignUpForm className={classes.formControl} setDisplayName={setDisplayName} />
          </DialogContent>
        </Box>
      )}
    </Dialog>
  );
}

LoginDialog.propTypes = {
  onClose: PropType.func.isRequired,
  open: PropType.bool.isRequired,
};

export default LoginDialog;
