import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

firebase.initializeApp({
  apiKey: 'AIzaSyBq3ZLbWaIhtTElvxM7YCNAuhPem8KrIME',
  authDomain: 'mineral-nebula-270422.firebaseapp.com',
  projectId: 'mineral-nebula-270422',
  storageBucket: 'mineral-nebula-270422.appspot.com',
  messagingSenderId: '868607059711',
  appId: '1:868607059711:web:d46597bc3360725d610eca',
  measurementId: 'G-P8EE50V8GZ',
});

const auth = firebase.auth();

const db = firebase.firestore();

export { auth, db };
