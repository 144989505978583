/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import { FormControl, TextField, Button } from '@material-ui/core';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Radio from '@material-ui/core/Radio';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { auth } from '../../services/firebaseUtils';
import { saveUserToFirestore } from '../../services/firebaseRequests';

function SignUpForm(props) {
  const { setDisplayName } = props;
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [
    createUserWithEmailAndPassword,
    user,
    loading,
    error,
  ] = useCreateUserWithEmailAndPassword(auth);

  if (error) {
    return <p>{error.message}</p>;
  }

  if (user && !user.displayName) {
    // TODO: this is a hack
    setDisplayName(`${firstname} ${lastname}`);
    saveUserToFirestore(user.user, 'signUpForm', { firstname, lastname });
  }

  return (
    <form>
      <FormControl type="submit">
        {/* <FormControlLabel value="female" control={<Radio />} label="Contractor" /> */}
        <div>
          <TextField
            required
            id="firstname"
            label="First Name"
            type="text"
            onChange={(e) => setFirstname(e.target.value)}
          />
          <TextField
            required
            id="lastname"
            type="text"
            label="Last Name"
            onChange={(e) => setLastname(e.target.value)}
          />
        </div>
        <TextField
          required
          id="email"
          type="email"
          label="Email"
          fullWidth
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          required
          id="password"
          type="password"
          label="Password"
          fullWidth
          error={password.length && password.length < 6}
          helperText={password.length && password.length < 6 ? 'must have at least 6 characters' : ''}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          required
          id="password"
          type="password"
          label="Confirm Password"
          fullWidth
          error={password !== confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {loading ? <CircularProgress /> : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => createUserWithEmailAndPassword(email, password)}
          >
            Sign Up
          </Button>
        )}
      </FormControl>
    </form>
  );
}

SignUpForm.propTypes = {
  setDisplayName: PropType.func.isRequired,
};

export default SignUpForm;
