import React, { useState } from 'react';
import PropType from 'prop-types';
import { TextField, Button, FormControl } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import firebase from 'firebase/app';
import { auth } from '../../services/firebaseUtils';
import { saveUserToFirestore } from '../../services/firebaseRequests';

function GoogleSignInComponent(props) {
  const { user } = props;
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider)
      .then((result) => {
        const newUser = result.user;
        saveUserToFirestore(newUser, 'googleAuth', {});
      });
  };
  if (!user) {
    return (
      <Button color="primary" variant="contained" onClick={signInWithGoogle}>Sign in with Google</Button>
    );
  }
  return null;
}

GoogleSignInComponent.propTypes = {
  user: PropType.string.isRequired,
};

function SignInForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [
    signInWithEmailAndPassword,
    user,
    loading,
    error,
  ] = useSignInWithEmailAndPassword(auth);

  if (error) {
    return <p>Error signing in...</p>;
  }

  return (
    <form>
      <FormControl type="submit">
        <TextField
          id="email"
          type="email"
          label="Email"
          fullWidth
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          id="password"
          type="password"
          label="Password"
          fullWidth
          onChange={(e) => setPassword(e.target.value)}
        />
        {
        loading ? <CircularProgress /> : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => signInWithEmailAndPassword(email, password)}
          >
            Sign In
          </Button>
        )
      }
        <p>or</p>
        <GoogleSignInComponent user={user} />
      </FormControl>
    </form>
  );
}

export default SignInForm;
