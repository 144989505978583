import React, { useMemo, useState } from 'react';
import {
  object, func, array, string,
} from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Button, Paper, TextField } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Dropdown from '../components/Dropdown';
import '../sass/fencedetailsform.scss';

const styles = (theme) => ({
  root: {
    width: '100%',
    padding: '2% 0 2% 0',
  },
  headingContainer: {
    height: '50px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    lineHeight: '50px',
    marginLeft: '15px',
  },
});

function FenceDetailsForm(props) {
  const [style, setStyle] = useState('');
  const [height, setHeight] = useState('');
  const [borderColor, setBorderColor] = useState('');
  const [panelColor, setPanelColor] = useState('');

  const {
    classes, addStyleType, styleTypes, fenceId, setFenceId,
  } = props;

  const styleTypeString = useMemo(() => {
    if (style !== '' && height !== '' && borderColor !== '' && panelColor !== '') {
      return `${style}-${height}-${borderColor}-${panelColor}`;
    }
    return '';
  }, [style, height, borderColor, panelColor]);

  const handleAddStyleType = (e) => {
    addStyleType(e, styleTypeString);
    setStyle('');
    setHeight('');
    setBorderColor('');
    setPanelColor('');
  };

  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.headingContainer}>
        <Typography className={classes.heading}>
          Fence customizations
        </Typography>
      </Paper>
      <div className="fencedetail-form dropdown-style">
        <TextField label="Job ID" value={fenceId} onChange={(event) => { setFenceId(event.target.value); }} style={{ margin: '4px' }} />
        <Dropdown
          dropdownLabel="Style"
          value={style}
          menuItems={[
            { id: 'fullprivacy', value: 'Full Privacy' },
            { id: 'picket_accent', value: 'Privacy Picket Accent' },
            { id: 'lattice_accent', value: 'Privacy Lattice Accent' },
            // { id: 'semiprivacy', value: 'Semi Privacy' },
          ]} // TODO: put in backend
          setItemValue={(e) => setStyle(e.target.value)}
        />
        <Dropdown
          dropdownLabel="Height"
          value={height}
          menuItems={[
            { id: '6', value: '6' },
            { id: '5', value: '5' },
            { id: '4', value: '4' },
            { id: '3', value: '3' },
          ]} // TODO: put in backend
          setItemValue={(e) => setHeight(e.target.value)}
        />
        <Dropdown
          dropdownLabel="Border Color"
          value={borderColor}
          menuItems={[
            { id: 'white', value: 'White' },
            { id: 'almond', value: 'Almond' },
            { id: 'khaki', value: 'Khaki' },
            { id: 'stone', value: 'Stone' },
            { id: 'signature', value: 'Signature' }, // TODO: put in backend
          ]}
          setItemValue={(e) => setBorderColor(e.target.value)}
        />
        <Dropdown
          dropdownLabel="Panel Color"
          value={panelColor}
          menuItems={[
            { id: 'white', value: 'White' },
            { id: 'almond', value: 'Almond' },
            { id: 'khaki', value: 'Khaki' },
            { id: 'stone', value: 'Stone' },
            { id: 'variegated', value: 'Variegated' },
            { id: 'signature', value: 'Signature' }, // TODO: put in backend
          ]}
          setItemValue={(e) => setPanelColor(e.target.value)}
        />
      </div>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAddStyleType}
        disabled={style === '' || height === '' || borderColor === '' || panelColor === ''}
      >
        Save as style type
      </Button>
      {
          styleTypes.filter(type => type !== "removal_only").map((type) => (
            <Chip
              key={`chip-${type}`}
              label={`${type}`}
              variant="outlined"
            />
          ))
        }
    </div>
  );
}

FenceDetailsForm.propTypes = {
  classes: object.isRequired,
  addStyleType: func.isRequired,
  styleTypes: array.isRequired,
  fenceId: string.isRequired,
  setFenceId: func.isRequired,
};

export default withStyles(styles)(FenceDetailsForm);
