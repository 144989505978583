/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './services/firebaseUtils';
import { fetchHomeowners } from './services/firebaseRequests';
import UniversalController from './controllers/UniversalController';
import { LoginDialog, SignOutComponent } from './components/LogInSignUp';
import HomeownerController from './controllers/HomeownerController';
import LOGO from './images/logo1.png';
import ErrorBoundary from './components/ErrorBoundary';

const drawerWidth = '208';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    display: 'flex',
  },
  drawer: {
    flexShrink: 0,
    width: `${drawerWidth}px`,
  },
  drawerPaper: {
    width: `${drawerWidth}px`,
  },
  title: {
    flexGrow: 1,
  },
  userInfoDiv: {
    marginLeft: 'calc(100% - 300px)',
    display: 'flex',
    width: '100%',
  },
}));

function App() {
  const classes = useStyles();
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [canFetchHomeowners, setCanFetchHomeowners] = useState(true);
  const [user] = useAuthState(auth);
  const [displayName, setDisplayName] = useState('');
  const [allHomeowners, setAllHomeowners] = useState({});

  function loginOnClickHandler() {
    setLoginDialogOpen(true);
  }

  function handleLoginDialogClick() {
    setLoginDialogOpen(!loginDialogOpen);
  }

  useEffect(() => {
    console.log('allHomeowners ', Object.keys(allHomeowners).length)
    if (user && canFetchHomeowners ) {
      fetchHomeowners(user).then((homeowners) => {
        setAllHomeowners(homeowners);
      });
      setCanFetchHomeowners(false);
    }
  }, [user, allHomeowners]);

  return (
    <ErrorBoundary>
      <Router>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar} elevation={1}>
            <Toolbar>
              <div className={classes.userInfoDiv}>
                {user
                  ? (
                    <>
                      <Typography variant="h6" noWrap>
                        {`Hello, ${displayName || user.displayName}`}
                      </Typography>
                      <SignOutComponent setCanFetchHomeowners={setCanFetchHomeowners} />
                    </>
                  )
                  : (
                    <Button variant="contained" color="inherit" onClick={loginOnClickHandler}>Login</Button>
                  )}
              </div>
              <LoginDialog
                open={loginDialogOpen && !user}
                onClose={handleLoginDialogClick}
                setDisplayName={setDisplayName}
              />
              { /* TODO: add firebase auth error message */}
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <img src={LOGO} alt="logo" />
            <List>
              <ListItem button>
                <Link to="/">
                  <ListItemText primary="New job" />
                </Link>
              </ListItem>
              <ListItem button>
                <Link to="/homeowners">
                  <ListItemText primary="Homeowners" />
                </Link>
              </ListItem>
              <ListItem button>
                <ListItemText primary="All jobs" />
              </ListItem>
              <Divider />
              <ListItem button>
                <ListItemText primary="Services" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="City" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Vendor" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="About us" />
              </ListItem>
            </List>
          </Drawer>
          <Switch>
            <Route path="/homeowners">
              <HomeownerController
                user={user}
                allHomeowners={allHomeowners}
                setAllHomeowners={setAllHomeowners}
                setCanFetchHomeowners={setCanFetchHomeowners}
              />
            </Route>
            <Route path="/">
              <UniversalController
                user={user}
                allHomeowners={allHomeowners}
              />
            </Route>
          </Switch>
        </div>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
