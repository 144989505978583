import React from "react";
import PropType from "prop-types";
import { DialogTitle, Typography, Button, TableHead } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Dialog from "@material-ui/core/Dialog";
import LinearProgress from "@material-ui/core/LinearProgress";
import CurrencyFormat from "react-currency-format";
import { host } from '../../env';

const styles = {
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "80vh",
  },
};

const useSectionStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "none",
    },
    backgroundColor: "#FAFAFA",
  },
  header: {
    overflow: "none",
  },
});

const StyledTableContainer = withStyles({
  root: {
    margin: "0 30px 20px 30px",
    width: "inherit",
  },
})(TableContainer);

const TableCellNoOutline = withStyles({
  root: {
    borderBottom: "none",
  },
})(TableCell);

const makeDialogTitle = (jobId) => (
  <DialogTitle>
    <Typography variant="subtitle1" align="left">
      {`JOB ID: ${jobId}`}
    </Typography>
  </DialogTitle>
);

function getSubKeyFontWeight(subKey) {
  return subKey === "total" ? "bold" : "normal";
}

function getCurrencyFormat(props) {
  const { cellValue, prefixValue, noDecimals, suffixValue } = props;
  if (noDecimals) {
    return (
      <>
        <CurrencyFormat
          value={cellValue}
          displayType="text"
          thousandSeparator
          prefix={prefixValue}
          suffix={suffixValue}
          decimalScale={2}
        />
      </>
    );
  }
  return (
    <>
      <CurrencyFormat
        value={cellValue}
        displayType="text"
        thousandSeparator
        prefix={prefixValue}
        suffix={suffixValue}
        decimalScale={2}
        fixedDecimalScale
      />
    </>
  );
}

function getMatrialRow(props) {
  const { materialCount, cost, page, rowsPerPage, selectedStyle } = props;
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCellNoOutline>Material name</TableCellNoOutline>
          <TableCellNoOutline align="right">Count</TableCellNoOutline>
          <TableCellNoOutline align="right">Cost</TableCellNoOutline>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(materialCount[selectedStyle])
          .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
          .map((key) => (
            <TableRow key={key}>
              <TableCellNoOutline>{key.replace("_", " ")}</TableCellNoOutline>
              <TableCellNoOutline align="right">
                {getCurrencyFormat({
                  cellValue: materialCount[selectedStyle][key],
                  prefixValue: "",
                  noDecimals: true,
                })}
              </TableCellNoOutline>
              <TableCellNoOutline align="right">
                {getCurrencyFormat({ cellValue: cost[selectedStyle][key], prefixValue: "$" })}
              </TableCellNoOutline>
            </TableRow>
          ))}
      </TableBody>
    </>
  );
}

function getPricingRow(props) {
  const { pricing, page, rowsPerPage } = props;
  const displayPricing = {};
  Object.keys(pricing).map((styleNameKey) =>
    // eslint-disable-next-line array-callback-return
    Object.keys(pricing[styleNameKey]).map((nameKey) => {
      if (!(styleNameKey === "project")) {
        const currentKey = styleNameKey+nameKey;
        displayPricing[currentKey] = pricing[styleNameKey][nameKey];
        displayPricing[currentKey].nameKey = nameKey;
        delete displayPricing[currentKey].share_price.total
      } else {
        const currentKey = styleNameKey+nameKey;
        displayPricing[currentKey] = pricing[styleNameKey][nameKey];
        displayPricing[currentKey].nameKey = nameKey;
        displayPricing[currentKey].style_name = styleNameKey;
      }
      
      // if (!(styleNameKey === "project")) {
      //   Object.keys(pricing[styleNameKey][nameKey].share_price).forEach((lineKey) => {
      //     console.log('keys: ', Object.keys(pricing[styleNameKey][nameKey]));
      //     const currentKey = styleNameKey+nameKey;
      //     if (!(nameKey in displayPricing)) {
      //       displayPricing[currentKey] = {};
      //     }
      //     displayPricing[currentKey][lineKey] = pricing[styleNameKey][nameKey].share_price[lineKey];
      //     console.log('displayPricing: ', displayPricing[currentKey][lineKey]);
      //   })
      // }
    })
  );
  console.log('displayPricing: ', displayPricing);
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCellNoOutline>Homeowner</TableCellNoOutline>
          <TableCellNoOutline align="right">Line Reference</TableCellNoOutline>
          <TableCellNoOutline align="right">Line Style</TableCellNoOutline>
          <TableCellNoOutline align="right">Length</TableCellNoOutline>
          <TableCellNoOutline align="right">Cost</TableCellNoOutline>
          <TableCellNoOutline align="right">Cost/Ft</TableCellNoOutline>
          <TableCellNoOutline align="right">Price</TableCellNoOutline>
          <TableCellNoOutline align="right">Price/Ft</TableCellNoOutline>
          <TableCellNoOutline align="right">Net Income</TableCellNoOutline>
          <TableCellNoOutline align="right">Net Income/Ft</TableCellNoOutline>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(displayPricing)
          .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
          .map((currentKey) => (
            <>
              {Object.keys(displayPricing[currentKey].share_price).map((lineKey) => (
                <>
                  <TableRow
                    key={currentKey}
                    style={{
                      backgroundColor: lineKey === "total" ? "#FAFAFA" : "",
                    }}
                  >
                    <TableCellNoOutline
                      component="th"
                      scope="row"
                      style={{ fontWeight: getSubKeyFontWeight(lineKey) }}
                    >
                      {displayPricing[currentKey].nameKey}
                    </TableCellNoOutline>
                    <TableCellNoOutline
                      component="th"
                      scope="row"
                      align="right"
                      style={{ fontWeight: getSubKeyFontWeight(lineKey) }}
                    >
                      {lineKey}
                    </TableCellNoOutline>
                    <TableCellNoOutline
                      component="th"
                      scope="row"
                      align="right"
                      style={{ fontWeight: getSubKeyFontWeight(lineKey) }}
                    >
                      {displayPricing[currentKey].style_name}
                    </TableCellNoOutline>
                    <TableCellNoOutline
                      component="th"
                      scope="row"
                      align="right"
                      style={{ fontWeight: getSubKeyFontWeight(lineKey) }}
                    >
                      {displayPricing[currentKey].len[lineKey]}
                    </TableCellNoOutline>
                    <TableCellNoOutline
                      align="right"
                      style={{ fontWeight: getSubKeyFontWeight(lineKey) }}
                    >
                      {getCurrencyFormat({
                        cellValue: displayPricing[currentKey].cost[lineKey],
                        prefixValue: "$",
                      })}
                    </TableCellNoOutline>
                    <TableCellNoOutline
                      align="right"
                      style={{ fontWeight: getSubKeyFontWeight(lineKey) }}
                    >
                      {getCurrencyFormat({
                        cellValue:
                          displayPricing[currentKey].cost[lineKey] /
                          displayPricing[currentKey].len[lineKey],
                        prefixValue: "$",
                        suffixValue: "/ft",
                      })}
                    </TableCellNoOutline>
                    <TableCellNoOutline
                      align="right"
                      style={{ fontWeight: getSubKeyFontWeight(lineKey) }}
                    >
                      {getCurrencyFormat({
                        cellValue: displayPricing[currentKey].share_price[lineKey],
                        prefixValue: "$",
                      })}
                    </TableCellNoOutline>
                    <TableCellNoOutline
                      align="right"
                      style={{ fontWeight: getSubKeyFontWeight(lineKey) }}
                    >
                      {getCurrencyFormat({
                        cellValue:
                          displayPricing[currentKey].share_price[lineKey] /
                          displayPricing[currentKey].len[lineKey],
                        prefixValue: "$",
                        suffixValue: "/ft",
                      })}
                    </TableCellNoOutline>
                    <TableCellNoOutline
                      align="right"
                      style={{ fontWeight: getSubKeyFontWeight(lineKey) }}
                    >
                      {getCurrencyFormat({
                        cellValue: displayPricing[currentKey].net[lineKey],
                        prefixValue: "$",
                      })}
                    </TableCellNoOutline>
                    <TableCellNoOutline
                      align="right"
                      style={{ fontWeight: getSubKeyFontWeight(lineKey) }}
                    >
                      {getCurrencyFormat({
                        cellValue:
                          displayPricing[currentKey].net[lineKey] /
                          displayPricing[currentKey].len[lineKey],
                        prefixValue: "$",
                        suffixValue: "/ft",
                      })}
                    </TableCellNoOutline>
                  </TableRow>
                </>
              ))}
            </>
          ))}
      </TableBody>
    </>
  );
}

function getQuoteSections(props) {
  const { materialCount, cost, pricing, fenceLines, title, selectedStyle, getRowMethod } =
    props;

  const [open, setOpen] = React.useState(true);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const classes = useSectionStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const pricingRows = () => {
    let sum = 0;
    for (let i = 0; i < Object.keys(pricing).length; i += 1) {
      const i_key = Object.keys(pricing)[i];
      for (let j = 0; j < Object.keys(pricing[i_key]).length; j += 1) {
        const j_key = Object.keys(pricing[i_key])[j];
        sum += Object.keys(pricing[i_key][j_key].share_price).length;
      }
    }
    return sum;
  };

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
        className={classes.root}
      >
        <Typography sx={{ flex: "1 1 100%" }} component="div" variant="h6">
          {title}
        </Typography>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Toolbar>
      {open ? (
        <>
          <TableContainer>
            <Table aria-label="collapsible table" size="small">
              {getRowMethod({
                materialCount,
                cost,
                page,
                rowsPerPage,
                pricing,
                fenceLines,
                selectedStyle,
              })}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[
              3,
              5,
              10,
              {
                value: pricing
                  ? pricingRows()
                  : Object.keys(materialCount).length,
                label: "All",
              },
            ]}
            component="div"
            count={pricing ? pricingRows() : Object.keys(materialCount).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : null}
    </>
  );
}

function getSummarySection(props) {
  const { materialCount, cost, pricing, title, splitedStyle, selectedStyle } = props;

  const [open, setOpen] = React.useState(true);

  const classes = useSectionStyles();

  const totalCost = () => {
    // const reducer = (previousValue, currentValue) =>
    //   previousValue + currentValue;
    // return Object.values(cost).reduce(reducer);
    return pricing.project.total.cost
  };

  const totalRevenue = () => {
    return pricing.project.total.share_price;
    // let total = 0;
    // console.log('pricing: ');
    // console.log(pricing);
    // Object.keys(pricing.project).forEach((ownerKey) => {
    //   total += pricing.project[ownerKey].share_price.total;
    // });
    // return total;
  };


  console.log('totalCost: ', totalCost());
  console.log('totalRevenue: ', totalRevenue());
  console.log('cost: ', cost);
  console.log('pricing: ', pricing);
  console.log('materialCount: ', materialCount);
  console.log('splitedStyle: ', splitedStyle);
  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
        className={classes.root}
      >
        <Typography sx={{ flex: "1 1 100%" }} component="div" variant="h6">
          {title}
        </Typography>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Toolbar>
      {open ? (
        <>
          <TableContainer>
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow>
                  <TableCellNoOutline>Description</TableCellNoOutline>
                  <TableCellNoOutline align="right">Amount</TableCellNoOutline>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCellNoOutline component="th" scope="row">
                    Style
                  </TableCellNoOutline>
                  <TableCellNoOutline align="right">
                    {splitedStyle[0]}
                  </TableCellNoOutline>
                </TableRow>
                <TableRow>
                  <TableCellNoOutline component="th" scope="row">
                    Panel Color
                  </TableCellNoOutline>
                  <TableCellNoOutline align="right">
                    {splitedStyle[3]}
                  </TableCellNoOutline>
                </TableRow>
                <TableRow>
                  <TableCellNoOutline component="th" scope="row">
                    Border Color
                  </TableCellNoOutline>
                  <TableCellNoOutline align="right">
                    {splitedStyle[2]}
                  </TableCellNoOutline>
                </TableRow>
                <TableRow>
                  <TableCellNoOutline component="th" scope="row">
                    Height
                  </TableCellNoOutline>
                  <TableCellNoOutline align="right">
                    {splitedStyle[1]}
                    &nbsp; ft
                  </TableCellNoOutline>
                </TableRow>
                <TableRow>&nbsp;</TableRow>
                <TableRow>
                  <TableCellNoOutline component="th" scope="row">
                    Total Fence Length
                  </TableCellNoOutline>
                  <TableCellNoOutline align="right">
                    {getCurrencyFormat({
                      cellValue: Object.values(cost).length > 0 ? totalCost() / materialCount[selectedStyle].plastic_TandG : 0,
                      prefixValue: "",
                      noDecimals: true,
                      suffixValue: " ft",
                    })}
                  </TableCellNoOutline>
                </TableRow>
                <TableRow>
                  <TableCellNoOutline component="th" scope="row">
                    Average Cost Per Foot
                  </TableCellNoOutline>
                  <TableCellNoOutline align="right">
                    {getCurrencyFormat({
                      cellValue: Object.values(cost).length > 0 ? totalCost() / materialCount[selectedStyle].plastic_TandG : 0,
                      prefixValue: "$",
                      suffixValue: "/ft",
                    })}
                  </TableCellNoOutline>
                </TableRow>
                <TableRow>
                  <TableCellNoOutline component="th" scope="row">
                    Number of Gates
                  </TableCellNoOutline>
                  <TableCellNoOutline align="right">
                    {getCurrencyFormat({
                      cellValue: Object.values(cost).length > 0 ? materialCount[selectedStyle].gate_hingeboxes : 0,
                      prefixValue: "",
                      noDecimals: true,
                    })}
                  </TableCellNoOutline>
                </TableRow>
                <TableRow>&nbsp;</TableRow>
                {Object.keys(pricing.project).filter(key => key !== 'total').map((ownerKey) => (
                  <>
                    <TableRow key={ownerKey}>
                      <TableCellNoOutline component="th" scope="row">
                        {ownerKey}
                        &nbsp; Revenue
                      </TableCellNoOutline>
                      <TableCellNoOutline align="right">
                        {getCurrencyFormat({
                          cellValue: pricing.project[ownerKey].share_price.total,
                          prefixValue: "$",
                        })}
                      </TableCellNoOutline>
                    </TableRow>
                  </>
                ))}
                <TableRow>
                  <TableCellNoOutline
                    component="th"
                    scope="row"
                    style={{ fontWeight: "bold" }}
                  >
                    Total Revenue
                  </TableCellNoOutline>
                  <TableCellNoOutline
                    align="right"
                    style={{ fontWeight: "bold" }}
                  >
                    {getCurrencyFormat({
                      cellValue: totalRevenue(),
                      prefixValue: "$",
                    })}
                  </TableCellNoOutline>
                </TableRow>
                <TableRow>
                  <TableCellNoOutline
                    component="th"
                    scope="row"
                    style={{ fontWeight: "bold", color: "red" }}
                  >
                    Total Cost
                  </TableCellNoOutline>
                  <TableCellNoOutline
                    align="right"
                    style={{ fontWeight: "bold", color: "red" }}
                  >
                    {getCurrencyFormat({
                      cellValue: Object.values(cost).length > 0 ? totalCost() : 0,
                      prefixValue: "$",
                    })}
                  </TableCellNoOutline>
                </TableRow>
                <TableRow style={{ backgroundColor: "#FAFAFA" }}>
                  <TableCellNoOutline
                    component="th"
                    scope="row"
                    style={{ fontWeight: "bold", color: "green" }}
                  >
                    Net Income
                  </TableCellNoOutline>
                  <TableCellNoOutline
                    align="right"
                    style={{ fontWeight: "bold", color: "green" }}
                  >
                    {getCurrencyFormat({
                      cellValue: Object.values(cost).length > 0 ? totalRevenue() - totalCost() : totalRevenue(),
                      prefixValue: "$",
                    })}
                  </TableCellNoOutline>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : null}
    </>
  );
}

const handleQuotePDFs = (pricing) => {
  fetch(`${host}/sendquotes`, {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(pricing),
  });
};

const handleMaterialPDF = (material) => {
  fetch(`${host}/sendmaterials`, {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(material),
  });
};

function QuoteDialog(props) {
  const { onClose, open, jobId, response, isFetching, fenceStyleType, gateStyleType, } = props;

  if (!isFetching && response) {
    let styleTypes = {};
  
    console.log('fenceStyleType: ', fenceStyleType);
    console.log('gateStyleType: ', gateStyleType);
    if (Object.keys(fenceStyleType).length === 0) {
      styleTypes = gateStyleType;
    } else {
      styleTypes = fenceStyleType;
    }
    console.log('styleTypes: ', styleTypes);
  
    let selectedStyle = 'removal_only';
    let splitedStyle = ['removal_only', 'removal_only', 'removal_only', 'removal_only'];
    for (const styleType of Object.values(styleTypes)) {
      if (styleType !== 'removal_only') {
        splitedStyle = styleType.split("-");
        selectedStyle = styleType;
        break;
      }
    }
  
    return (
      <Dialog
        root={{ paper: styles.dialogPaper }}
        onClose={onClose}
        open={open}
        fullWidth
        maxWidth="md"
      >
        {makeDialogTitle(jobId)}
        <StyledTableContainer>
          <Table aria-label="collapsible table">
            <TableBody>
              {Object.values(response.cost).length > 0 ? getQuoteSections({
                materialCount: response?.material,
                cost: response?.cost,
                title: "Material List",
                selectedStyle,
                getRowMethod: getMatrialRow, 
              }): 'Removal Only Job, No Material Required.'}
            </TableBody>
            <Button
              color="secondary"
              variant="contained"
              style={{ marginLeft: "30px", marginBottom: "20px" }}
              onClick={() => handleMaterialPDF(response)}
              disabled={Object.values(response.cost).length === 0 ? true : false}
            >
              Order Materials
            </Button>
          </Table>
          <Table aria-label="collapsible table">
            <TableBody>
              {getQuoteSections({
                pricing: response?.pricing,
                fenceLines: response?.fence_lines,
                title: "Homeowner Quotes",
                selectedStyle,
                getRowMethod: getPricingRow,
              })}
            </TableBody>
            <Button
              color="secondary"
              variant="contained"
              style={{ marginLeft: "30px", marginBottom: "20px" }}
              onClick={() => handleQuotePDFs(response)}
            >
              Send Quotes
            </Button>
          </Table>
          <Table aria-label="collapsible table">
            <TableBody>
              {getSummarySection({
                materialCount: response?.material,
                cost: response?.cost,
                pricing: response?.pricing,
                title: "Job Summary",
                splitedStyle,
                selectedStyle,
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Dialog>
    );
  }
  return (
    <Dialog
      root={{ paper: styles.dialogPaper }}
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="md"
    >
      <LinearProgress color="secondary" />
    </Dialog>
  );
}

QuoteDialog.propTypes = {
  onClose: PropType.func.isRequired,
  open: PropType.bool.isRequired,
  jobId: PropType.string,
  response: PropType.object,
  isFetching: PropType.bool.isRequired,
  fenceStyleType: PropType.object,
  gateStyleType: PropType.object,
};

QuoteDialog.defaultProps = {
  jobId: "######",
  response: null,
  fenceStyleType: null,
};

export default QuoteDialog;
