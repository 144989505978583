import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { deleteHomeowner } from '../../services/firebaseRequests';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    minHeight: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function HomeownerCard(props) {
  const { homeowner, homeownerId, user, deleteHomeownerFromState} = props;
  const classes = useStyles();
  console.log(homeowner);

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Homeower
        </Typography>
        <Typography variant="h5" component="h2">
          {`${homeowner?.lastname} ${homeowner?.firstname}`}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Address
        </Typography>
        <Typography variant="body2" component="p">
          {homeowner?.address}
        </Typography>
        <Typography variant="body2" component="p">
          {homeowner?.city + ", " + homeowner?.province}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">View Jobs</Button>
        <Button size="small">Edit</Button>
        <Button onClick={() => deleteHomeowner(homeownerId, user, deleteHomeownerFromState)} size="small">Delete</Button>
      </CardActions>
    </Card>
  );
}

HomeownerCard.propTypes = {
  homeowner: PropTypes.object.isRequired,
  homeownerId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  deleteHomeownerFromState: PropTypes.func,
};
