import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  number, array, func, string,
} from 'prop-types';
import MultipleSelectDropdown from '../components/MultipleSelectDropdown';
import '../sass/jobdetailform.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
  },
  headingContainer: {
    height: '50px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    lineHeight: '50px',
    marginLeft: '15px',
  },
  body: {
    width: '100%',
    display: 'block',
    flexWrap: 'nowrap',
  },
  button: {
    margin: '3% 2% 0 2%',
    width: '40%',
  },
}));

export default function SearchHomeowner(props) {
  const classes = useStyles();
  const {
    allHomeowners, handleLineOwnerShipOnClick, itemType, itemId, label, isLine, lineKey, lineOwnership, isFence,
  } = props;
  // const firstHomeowner = Object.entries(allHomeowners)[0][1];
  // console.log('allHomeowners ', allHomeowners);
  // console.log('firstHomeowner ', firstHomeowner);

  return (
    <div className={classes.root}>
      <div className="jobdetail-form ownership-dropdown-style">
        <MultipleSelectDropdown
          isLine={isLine}
          isFence={isFence}
          dropdownLabel={label}
          value="homeowner"
          menuItems={allHomeowners}
          setItemValue={handleLineOwnerShipOnClick}
          lineKey={lineKey}
          lineOwnership={lineOwnership}
          itemType={itemType}
          itemId={itemId}
          IconComponent={() => (<> </>)}
        />
      </div>
    </div>
  );
}

SearchHomeowner.propTypes = {
  // user: object,
  allHomeowners: array.isRequired,
  // setNewHomeownerAdded: func.isRequired,
  handleLineOwnerShipOnClick: func.isRequired,
  itemType: string.isRequired,
  itemId: number.isRequired,
  label: string.isRequired,
  isLine: PropTypes.bool.isRequired,
  isFence: PropTypes.bool,
  lineKey: PropTypes.number,
  lineOwnership: PropTypes.object
};
