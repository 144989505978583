import React, { useState } from 'react';
import { object, array } from 'prop-types';
import MapController from './MapController';

function UniversalController(props) {
  const { user, allHomeowners } = props;
  const [currUnit, setCurrUnit] = useState('ft');
  const [prevUnit, setPrevUnit] = useState('m');

  const selectMeasurementUnit = (value) => {
    switch (value) {
      case 'ft':
        setCurrUnit('ft');
        setPrevUnit((prevState) => (prevState));
        break;
      case 'in':
        setCurrUnit('in');
        setPrevUnit((prevState) => (prevState));
        break;
      case 'm':
        setCurrUnit('m');
        setPrevUnit((prevState) => (prevState));
        break;
      default:
        break;
    }
  };

  return (
    <MapController
      prevUnit={prevUnit}
      currUnit={currUnit}
      selectUnit={selectMeasurementUnit}
      user={user}
      allHomeowners={allHomeowners}
    />
  );
}

UniversalController.propTypes = {
  user: object,
  allHomeowners: array.isRequired,
};

UniversalController.defaultProps = {
  user: null,
};

export default UniversalController;
