// note: google map latLng are in degrees
/* eslint-disable max-len */
const EARTH_RADIUS = 3958.6;
const ROUNDING_VALUE = 0.2;

function customRoundup(n) {
  /*
   * e.g. for anything between x - x.2, round to x.2
   * for anything x.21 - x.9, round to x + 1
   */
  const f = Math.floor(n);
  if (n - f > ROUNDING_VALUE) return f + 1;
  return f;
}

function haversineDistance(mk1, mk2) {
  const rlat1 = mk1.lat() * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = mk2.lat() * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (mk2.lng() - mk1.lng()) * (Math.PI / 180); // Radian difference (longitudes)

  const d =
    2 *
    EARTH_RADIUS *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    );
  return customRoundup(d);
}

function getGeoMidpoint(pos1, pos2) {
  // https://stackoverflow.com/questions/4656802/midpoint-between-two-latitude-and-longitude
  const rlat1 = pos1.lat() * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = pos2.lat() * (Math.PI / 180); // Convert degrees to radians
  const rlon1 = pos1.lng() * (Math.PI / 180);
  const difflon = (pos2.lng() - pos1.lng()) * (Math.PI / 180); // Radian difference (longitudes)

  const Bx = Math.cos(rlat2) * Math.cos(difflon);
  const By = Math.cos(rlat2) * Math.sin(difflon);
  const lat3 = Math.atan2(
    Math.sin(rlat1) + Math.sin(rlat2),
    Math.sqrt((Math.cos(rlat1) + Bx) * (Math.cos(rlat1) + Bx) + By * By)
  );
  const lon3 = rlon1 + Math.atan2(By, Math.cos(rlat1) + Bx);

  return { lat: (lat3 * 180) / Math.PI, lng: (lon3 * 180) / Math.PI };
}

function generateRoute(path) {
  const route = [];
  path.forEach((node) => {
    const lat = node.lat();
    const lng = node.lng();
    route.push({ lat, lng });
  });

  return route;
}

function generateTurfPoint(point) {
  const lat = point.lat();
  const lng = point.lng();

  return [lng, lat];
}

function generateLine(route) {
  const line = {
    p1: {},
    p2: {},
    removal: false,
  };
  const node1 = route[route.length - 2];
  const node2 = route[route.length - 1];
  line.p1.lat = node1.lat;
  line.p1.lng = node1.lng;
  line.p2.lat = node2.lat;
  line.p2.lng = node2.lng;

  return line;
}

function generateDropdownMenuItemsFromArray(array) {
  const out = [];
  array.forEach((arr, id) => {
    out.push({
      id,
      value: arr,
    });
  });

  return out;
}

export {
  customRoundup,
  haversineDistance,
  getGeoMidpoint,
  generateRoute,
  generateLine,
  generateTurfPoint,
  generateDropdownMenuItemsFromArray,
};
