const DefaultZoomLevel = 10;

const DefaultLocation = {
  lat: 53.5461,
  lng: -113.4901,
};

const FENCE = 'fence';

const GATE = 'gate';

const MEASUREMENT_UNIT_CHOICES = [
  {
    id: 'm',
    value: 'm',
  },
  {
    id: 'ft',
    value: 'ft',
  },
  {
    id: 'in',
    value: 'in',
  },
];

export {
  DefaultZoomLevel,
  DefaultLocation,
  FENCE,
  GATE,
  MEASUREMENT_UNIT_CHOICES,
};
