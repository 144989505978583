import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 4,
    minWidth: 120,
  },
}));

export default function MultipleSelectDropdown(props) {
  const classes = useStyles();
  const {
    dropdownLabel, menuItems, variant, IconComponent, lineKey, lineOwnership, isLine,
    setItemValue, isFence,
  } = props;
  const [selections, setSelections] = useState((menuItems.length > 0 && isLine) ? [Object.entries(menuItems)[0][1]] : []);
  if (isLine) {
    if ( (!Object.keys(lineOwnership).includes(lineKey)) && (selections.length === 1) ) {
      const syntheticEvent = {
        target: {
          value: [Object.entries(menuItems)[0][1]],
        },
        preventDefault: () => {},
      };
      console.log('isFece ', isFence);
      let lineType;
      if (isFence) {
        lineType = 'fence';
      } else {
        lineType = 'gate';
      }
      setItemValue(syntheticEvent, lineType, lineKey);
    }
  }

  const handleChange = (event, ...args) => {
    const valueOfChange = event.target.value;
    if (selections.includes(valueOfChange)) {
      setSelections(selections.filter((item) => item !== valueOfChange));
    } else {
      setSelections(event.target.value);
    }

    setSelections(event.target.value);
    setItemValue(event, ...args);
  };
  // console.log('selections ', selections);
  // console.log('defaultValue ', defaultValue);
  // console.log('menuItems ', menuItems);
  // console.log('menuItems1 ', Object.entries(menuItems)[0][1]);

  return (
    <FormControl variant={variant} className={classes.formControl}>
      <InputLabel id="select-input-helper-label" htmlFor="uncontrolled-native">{dropdownLabel}</InputLabel>
      <Select
        IconComponent={IconComponent}
        labelId="select-helper-label"
        id="simple-select-helper"
        value={selections}
        onChange={handleChange}
        multiple
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {
            Object.values(menuItems).map((item, index) => (
              <MenuItem
                key={index}
                value={item}
              >
                {item}
              </MenuItem>
            ))
          }
      </Select>
    </FormControl>
  );
}

MultipleSelectDropdown.propTypes = {
  dropdownLabel: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  classes: PropTypes.shape({
    formControl: PropTypes.string,
  }).isRequired,
  setItemValue: PropTypes.func.isRequired,
  isLine: PropTypes.bool.isRequired,
  isFence: PropTypes.bool,
  lineKey: PropTypes.number,
  lineOwnership: PropTypes.object,
  variant: PropTypes.string,
  IconComponent: PropTypes.element,
};

MultipleSelectDropdown.defaultProps = {
  defaultValue: '',
  variant: 'standard',
  IconComponent: ArrowDropDownIcon,
};
