/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Convert from "convert-units";
import Dropdown from "../components/Dropdown";
import QuoteDialog from "../components/Quote/QuoteDialog";
import LineCustomization from "./LineCustomization";
import FenceDetailsForm from "../forms/FenceDetailsForm";
import { MEASUREMENT_UNIT_CHOICES } from "../utils/constant";
import { customRoundup } from "../utils/utils";
import "../sass/jobdetailform.scss";
import { host } from '../env';

class JobController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quoteDialogOpen: false,
      quoteResponse: null,
      isFetching: false,
      readyToQuoteErrorText: "",
      fenceId: "",
      selectedHomeowners: {},
      selectedHomeownersList: [],
      styleTypes: ['removal_only'], // collections of style types
    };
  }

  isReadyToQuote = () => {
    const {
      fenceOwnership,
      gateOwnership,
      fenceLines,
      gateLines,
      fenceStyleType,
      gateStyleType,
    } = this.props;
    const { selectedHomeownersList, styleTypes } = this.state;
    console.log("fenceStyleType "+Object.keys(fenceStyleType).length);
    console.log("gateStyleType "+Object.keys(gateStyleType).length);
    console.log("fenceOwnership "+Object.keys(fenceOwnership).length);
    console.log("gateOwnership "+Object.keys(gateOwnership).length);
    console.log("fenceLines "+Object.keys(fenceLines).length);
    console.log("gateLines "+Object.keys(gateLines).length);
    return (
      selectedHomeownersList.length &&
      styleTypes.length &&
      Object.keys(fenceOwnership).length === Object.keys(fenceLines).length &&
      Object.keys(gateOwnership).length === Object.keys(gateLines).length &&
      Object.keys(fenceStyleType).length === Object.keys(fenceLines).length &&
      Object.keys(gateStyleType).length === Object.keys(gateLines).length && 
      (Object.keys(fenceLines).length || Object.keys(gateLines).length)
    );
  };

  setSelectedHomeowners = (event) => {
    const {allHomeowners} = this.props;
    const items = event.target.value;
    this.setState(prevState => {
      const allHomeownersNames = Object.entries(allHomeowners).reduce(
        (acc, [key, homeowner]) => {
          acc[key] = `${homeowner.firstname} ${homeowner.lastname}`;
          return acc;
        },
        {}
      );
      let keyFound;
      for (let [key, value] of Object.entries(allHomeownersNames)) {
        if (value === items[items.length - 1]) {
          keyFound = key;
          break;
        }
      }
      let newObject = {...prevState.selectedHomeowners};
      newObject[keyFound] = allHomeowners[keyFound];
      
      return {selectedHomeowners: newObject, selectedHomeownersList: items};
    });
  };

  addStyleType = (_, type) => {
    const { styleTypes } = this.state;
    this.setState({ styleTypes: [type, ...styleTypes] });
  };

  handleQuoteDialogOpen = () => {
    this.setState(() => ({ isFetching: true }));
    const { 
      selectedHomeowners,
      fenceId,
     } = this.state;
    const {
      fenceLines,
      gateLines,
      fenceOwnership,
      gateOwnership,
      fenceDistanceArray,
      gateDistanceArray,
      fenceStyleType,
      gateStyleType,
      currUnit,
    } = this.props;

    const fenceDistanceInFeet = fenceDistanceArray.map(
      (obj) =>
        obj && {
          ...obj,
          data: customRoundup(Convert(obj.data).from("m").to("ft")),
        }
    );
    const gateDistanceInFeet = gateDistanceArray.map(
      (obj) =>
        obj && {
          ...obj,
          data: customRoundup(Convert(obj.data).from("m").to("ft")),
        }
    );
    console.log(selectedHomeowners);

    const data = JSON.stringify({
      selectedHomeowners,
      fenceLines,
      gateLines,
      fenceOwnership,
      gateOwnership,
      fenceStyleType,
      gateStyleType,
      fence_id: fenceId,
      contractor_id: "dlr",
      unit: currUnit,
      base_price: "medium",
      fenceDistanceArray: fenceDistanceInFeet,
      gateDistanceArray: gateDistanceInFeet,
    });
    this.setState(() => ({ quoteDialogOpen: true }));
    fetch(`${host}/quote`, {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then((response) => response.json())
      .then((quote) =>
        this.setState(() => ({ quoteResponse: quote, isFetching: false }))
      )
      .catch((error) => console.log(error));
  };

  handleQuoteDialogClose = () => {
    this.setState({ quoteDialogOpen: false });
  };

  render() {
    const {
      quoteDialogOpen,
      quoteResponse,
      isFetching,
      readyToQuoteErrorText,
      selectedHomeownersList,
      styleTypes,
      fenceId,
    } = this.state;
    const {
      totalLength,
      selectUnit,
      prevUnit,
      currUnit,
      user,
      allHomeowners,
      fences,
      gates,
      fenceLines,
      gateLines,
      handleLineOwnerShipOnClick,
      handleLineStyleTypeOnClick,
      fenceStyleType,
      gateStyleType,
      fenceOwnership,
      gateOwnership,
      updateFenceLineRemoval,
      updateGateLineRemoval,
    } = this.props;

    const allHomeownersNames = Object.entries(allHomeowners).reduce(
      (acc, [key, homeowner]) => {
        acc[key] = `${homeowner.firstname} ${homeowner.lastname}`;
        return acc;
      },
      {}
    );

    return (
      <div className="jobdetail-form">
        <div>
          {`Total Length: ${
            currUnit === "m"
              ? customRoundup(totalLength)
              : customRoundup(Convert(totalLength).from(prevUnit).to(currUnit))
          } ${currUnit}`}
        </div>
        <Dropdown
          dropdownLabel="Unit"
          value={currUnit}
          menuItems={MEASUREMENT_UNIT_CHOICES} // TODO: put in backend
          setItemValue={(e) => selectUnit(e.target.value)}
        />
        <FenceDetailsForm
          styleTypes={styleTypes}
          addStyleType={this.addStyleType}
          fenceId={fenceId}
          setFenceId={(value) => {
            this.setState(() => ({ fenceId: value }));
          }}
        />
        <LineCustomization
          fences={fences}
          gates={gates}
          fenceLines={fenceLines}
          gateLines={gateLines}
          user={user}
          allHomeowners={allHomeownersNames}
          handleLineOwnerShipOnClick={handleLineOwnerShipOnClick}
          handleLineStyleTypeOnClick={handleLineStyleTypeOnClick}
          selectedHomeowners={selectedHomeownersList}
          setSelectedHomeowners={this.setSelectedHomeowners}
          styleTypes={styleTypes}
          fenceStyleType={fenceStyleType}
          gateStyleType={gateStyleType}
          fenceOwnership={fenceOwnership}
          gateOwnership={gateOwnership}
          updateFenceLineRemoval={updateFenceLineRemoval}
          updateGateLineRemoval={updateGateLineRemoval}
        />
        <div>{readyToQuoteErrorText}</div>
        <Button
          variant="contained"
          color="secondary"
          onClick={this.handleQuoteDialogOpen}
          disabled={!this.isReadyToQuote()}
        >
          Get Quote
        </Button>
        <QuoteDialog
          open={quoteDialogOpen}
          onClose={this.handleQuoteDialogClose}
          response={quoteResponse}
          isFetching={isFetching}
          fenceStyleType={fenceStyleType}
          gateStyleType={gateStyleType}
        />
      </div>
    );
  }
}

JobController.propTypes = {
  totalLength: PropTypes.number.isRequired,
  selectUnit: PropTypes.func.isRequired,
  prevUnit: PropTypes.string.isRequired,
  currUnit: PropTypes.string.isRequired,
  fences: PropTypes.array.isRequired,
  gates: PropTypes.array.isRequired,
  fenceLines: PropTypes.array.isRequired,
  gateLines: PropTypes.array.isRequired,
  fenceOwnership: PropTypes.object.isRequired,
  gateOwnership: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  allHomeowners: PropTypes.array.isRequired,
  fenceDistanceArray: PropTypes.array.isRequired,
  gateDistanceArray: PropTypes.array.isRequired,
  fenceStyleType: PropTypes.object.isRequired,
  gateStyleType: PropTypes.object.isRequired,
  handleLineStyleTypeOnClick: PropTypes.func.isRequired,
  handleLineOwnerShipOnClick: PropTypes.func.isRequired,
  updateFenceLineRemoval: PropTypes.func.isRequired,
  updateGateLineRemoval: PropTypes.func.isRequired,
};

export default JobController;
