import React, { useMemo } from "react";
import { array, func, object } from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import SearchHomeowner from "../forms/SearchHomeowners";
import Dropdown from "../components/Dropdown";
import { FENCE, GATE } from "../utils/constant";
import { generateDropdownMenuItemsFromArray } from "../utils/utils";
import "../sass/fencedetailsform.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "2% 0 2% 0",
  },
  headingContainer: {
    height: "50px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "left",
    lineHeight: "50px",
    marginLeft: "15px",
  },
}));

const LineCustomization = (props) => {
  const classes = useStyles();
  const {
    fenceLines,
    gateLines,
    user,
    allHomeowners,
    styleTypes,
    fenceStyleType,
    gateStyleType,
    fenceOwnership,
    gateOwnership,
    handleLineOwnerShipOnClick,
    handleLineStyleTypeOnClick,
    selectedHomeowners,
    setSelectedHomeowners,
    updateFenceLineRemoval,
    updateGateLineRemoval,
  } = props;

  const styleTypesOptions = useMemo(
    () => generateDropdownMenuItemsFromArray(styleTypes),
    [styleTypes]
  );

  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.headingContainer}>
        <Typography className={classes.heading}>Line ownership</Typography>
      </Paper>
      <div>
        <SearchHomeowner
          isLine={false}
          label="Search homeowner(s)"
          user={user}
          allHomeowners={allHomeowners}
          handleLineOwnerShipOnClick={setSelectedHomeowners}
        />
        {selectedHomeowners.map((item) => (
          <Chip
            key={`chip-${item}`}
            label={item}
            variant="outlined"
            // onDelete={(e) => handleDeleteChip(e, item)}
          />
        ))}
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Fence ID</TableCell>
              <TableCell>Owner(s)</TableCell>
              <TableCell>Style</TableCell>
              <TableCell>Removal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(fenceLines).map(([lineKey, item]) => (
              <TableRow key={`fence-row-${item}`}>
                <TableCell>{`# ${lineKey}`}</TableCell>
                <TableCell>
                  <SearchHomeowner
                    isLine={true}
                    isFence={true}
                    label="Owner"
                    user={user}
                    lineKey={lineKey}
                    lineOwnership={fenceOwnership}
                    allHomeowners={selectedHomeowners}
                    handleLineOwnerShipOnClick={(e) =>
                      handleLineOwnerShipOnClick(e, FENCE, lineKey)
                    }
                  />
                </TableCell>
                <TableCell>
                  {console.log("key ", lineKey)}
                  {/* {console.log("styleTypesOptions a", styleTypesOptions)} */}
                  <Dropdown
                    dropdownLabel="Style Type"
                    menuItems={styleTypesOptions}
                    lineKey={lineKey}
                    lineStyleType={fenceStyleType}
                    isLine={true}
                    setItemValue={(e) =>
                      (handleLineStyleTypeOnClick(e, FENCE, lineKey))
                    }
                  />
                </TableCell>
                <TableCell>
                  {console.log("fenceStyleType ", fenceStyleType)}
                  {console.log("styleTypesOptions ", styleTypesOptions)}
                  {fenceStyleType[lineKey] === "removal_only" ? (
                    <Checkbox checked={true} disabled={true} />
                  ) : (
                    <Checkbox
                      checked={item.removal}
                      disabled={false}
                      onChange={(e) => {
                        updateFenceLineRemoval(lineKey, e.target.checked);
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell>Gate ID </TableCell>
              <TableCell>Owner(s)</TableCell>
              <TableCell>Style</TableCell>
              <TableCell>Removal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(gateLines).map(([lineKey, item]) => (
              <TableRow key={`gate-row-${item}`}>
                <TableCell>{`# ${lineKey}`}</TableCell>
                <TableCell>
                  <SearchHomeowner
                    isLine={true}
                    isFence={false}
                    label="Owner"
                    user={user}
                    lineKey={lineKey}
                    lineOwnership={gateOwnership}
                    allHomeowners={selectedHomeowners}
                    handleLineOwnerShipOnClick={(e) =>
                      handleLineOwnerShipOnClick(e, GATE, lineKey)
                    }
                  />
                </TableCell>
                <TableCell>
                  <Dropdown
                    dropdownLabel="Style Type"
                    menuItems={styleTypesOptions}
                    lineKey={lineKey}
                    lineStyleType={gateStyleType}
                    isLine={true}
                    setItemValue={(e) =>
                      handleLineStyleTypeOnClick(e, GATE, lineKey)
                    }
                  />
                </TableCell>
                <TableCell>
                  {console.log("item ", item)}
                  {gateStyleType[lineKey] === "removal_only" ? (
                    <Checkbox checked={true} disabled={true} />
                  ) : (
                    <Checkbox
                      checked={item.removal}
                      disabled={false}
                      onChange={(e) => {
                        updateGateLineRemoval(lineKey, e.target.checked);
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

LineCustomization.propTypes = {
  fenceLines: object.isRequired,
  gateLines: object.isRequired,
  user: object.isRequired,
  allHomeowners: array.isRequired,
  handleLineOwnerShipOnClick: func.isRequired,
  handleLineStyleTypeOnClick: func.isRequired,
  selectedHomeowners: array.isRequired,
  setSelectedHomeowners: func.isRequired,
  styleTypes: array.isRequired,
  fenceStyleType: object.isRequired,
  gateStyleType: object.isRequired,
  fenceOwnership: object.isRequired,
  gateOwnership: object.isRequired,
  updateFenceLineRemoval: func.isRequired,
  updateGateLineRemoval: func.isRequired,
};

export default LineCustomization;
