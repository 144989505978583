import firebase from 'firebase/app';
import { auth, db } from './firebaseUtils';

const fetchHomeowners = (user) => {
  //Get homeowners from querying contractor's id on homeowners collection
  const homeownersRef = db?.collection('homeowners').where('contractor', '==', user.uid);
  return homeownersRef.get().then((querySnapshot) => {
    const homeowners = {};
    querySnapshot.forEach((doc) => {
      homeowners[doc.id] = doc.data();
    }, []);
    return homeowners;
  }).catch((err) => {
    console.log('Error getting homeowner documents: ', err);
  });
};

function saveUserToFirestore(user, signInType, { firstname, lastname }) {
  const docRef = db?.collection('contractors').doc(user?.uid);
  return docRef.get().then((doc) => {
    if (!doc.exists) {
      console.log('No contractor exists');
      if (firstname && lastname) {
        auth.currentUser.updateProfile({
          displayName: `${firstname} ${lastname}`,
        }).then(() => {
          console.log('profile updated');
        }).catch((msg) => {
          console.log('error updating profile:', msg);
        });
    
        db.collection('contractors').doc(user.uid).set({
          firstname,
          lastname,
          email: user.email,
          signInType,
        })
          .then((docRef) => {
            console.log('Updated manual sign-up contractor info', docRef.id);
          })
          .catch((err) => {
            console.log('Error updating maunal sign-up contractor info collections:', err.message);
          });
      } else {
        db.collection('contractors').doc(user.uid).set({
          displayName: user.displayName,
          email: user.email,
          signInType,
        })
          .then((docRef) => {
            console.log('Added google auth contractor', docRef.id);
          })
          .catch((err) => {
            console.log('Error adding google auth contractor to collections:', err.message);
          });
      }
    } else {
      console.log('Contractor already exists');
    }
  }).catch((err) => {
    console.log('Error getting document:', err);
  });

}

const saveHomeowner = (user, firstname, lastname, email, phone, address,
  city, postcode, province) => {
  if (user) {
    console.log('user ', user.uid);
    return db.collection('homeowners').add({
      firstname,
      lastname,
      email,
      phone,
      address,
      city,
      postcode,
      province,
      contractor: user.uid,
    })
      .then((docRef) => {
        db.collection('contractors').doc(user.uid).update({
          homeowners: firebase.firestore.FieldValue.arrayUnion(db.doc(`homeowners/${docRef.id}`)),
        })
          .then(() => {
            console.log('Contractor info updated', user.id);
            return docRef.get();
          })
          .catch((error) => {
            console.log('Error adding homewowner to the contractor', error);
          });
        return docRef.get();
      })
      .catch((error) => {
        console.error('Error adding document: ', error);
      });
  }
  const promise = new Promise();
  return promise.resolve(true);
};

const deleteHomeowner = (homeownerId, user, deleteHomeownerFromState) => {

  deleteHomeownerFromState(homeownerId);
  //delete homeowner from homeowners collection
  db.collection('homeowners').doc(homeownerId).delete()
    .then(() => {
      console.log('Homeowner successfully deleted!');
    })
    .catch((error) => {
      console.error('Error removing homeowner: ', error);
    });

  //delete homeowner from contractor's homeowners array
  db.collection('contractors').doc(user.uid).update({
    homeowners: firebase.firestore.FieldValue.arrayRemove(db.doc(`homeowners/${homeownerId}`)),
  })
    .then(() => {
      console.log('Homeowner successfully deleted from contractor!');
    })
    .catch((error) => {
      console.error('Error removing homeowner from contractor: ', error);
    });

  }

export { fetchHomeowners, saveUserToFirestore, saveHomeowner, deleteHomeowner };
